<template lang="pug">
  span
    v-checkbox(
      :input-value="coursePhotoConsent"
      :disabled="loading"
      @click.prevent="toggle"
    )
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import apiCandidates from '@/services/api/api-candidates'
import BaseDialog from '@/components/global/actions/BaseDialog.vue'
import showingMixin from '@/mixins/showing.mixin'
import CandidateService from '../../candidates/core/candidates-service'

export default {
  name: 'CoursePhotoConsent',

  inject: ['svc', 'updateField'],

  mixins: [errorsMixin, showingMixin],

  props: {
    coursePhotoConsent: Boolean,
    candidateID: Number
  },

  data: () => ({
    loading: false
  }),

  computed: {

  },

  methods: {
    async toggle() {
      let val = this.coursePhotoConsent ? false : true
      await this.update()
      this.updateField(this.candidateID, 'course_photo_consent', val)
    },

    async update() {
      try {
        this.loading = true
        await new CandidateService(apiCandidates).toggleCoursePhotoConsent(this.candidateID)
        this.$notify({ type: 'success', text: 'Course photo consent updated'})
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      } finally {
        this.loading = false
      }
    },

  },

  components: {
    BaseDialog,
    appButton: () => import('@/components/global/actions/BaseButton.vue')
  }
}
</script>

<style lang="scss">
// .presentie-confirm {
//   padding: 15px;
// }
</style>
